import React, { Component } from 'react'
import CustomButton from '../../components/ui/customButton/CustomButton'
import Option from '../../components/dropdown/multiSelectDropdownOption/MultiSelectOption'
import MultiSelectDropdown from '../../components/dropdown/MultiSelectDropdown'
import ActionContainer from '../../components/ui/ActionTableComponent/actionContainer'
import ActionEditButton from '../../components/ui/ActionTableComponent/ActionEditButton'
import Table from '../../components/table/Table'
import RecordDetailModel from '../../components/recordDetail/RecordDetailModel'
import DuplicateRecordDetailModel from '../../components/recordDetail/DuplicateRecordDetailModel'
import BatchUploadServices from '../../services/axiosServices/apiServices/BatchUploadServices'
import { saveAs } from 'file-saver';
import SwalServices from '../../services/swalServices/SwalServices'
import { CommonValidationMessages, SuccessMessage } from '../../utils/Messages'
import moment from 'moment'
import { isValidForm, validate } from '../../utils/validation/CommonValidator'
import ValidationText from '../../utils/validation/ValidationText'
import BatchUploadDetailsModal from '../../components/recordDetail/BatchUploadDetailsModal'
import ModalBasic from '../../components/Model/ModalBasic'
import DuplicateEmailDetailModel from '../../components/recordDetail/DuplicateEmailDetailModel'
import DuplicateTransactionIdDetailModel from '../../components/recordDetail/DuplicateTransactionIdDetailModel'
import TextInput from '../../components/common/pageLoader/TextInput'


export default class BatchUpload extends Component {
  constructor(props) {
    super(props);
    this.batchUploadServices = new BatchUploadServices();
    this.swalServices = new SwalServices();

    var curr = new Date();
    curr.setDate(curr.getDate());

    this.state = {
      isLoading: false,
      source: [
        {
          value: 1,
          label: 'Give Asia'
        },
        {
          value: 2,
          label: 'Giving.SG'
        },
        {
          value: 3,
          label: 'Master'
        },
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: ""
      },
      setAddEditBatchUpload: false,
      setDuplicateRecordDetails: false,
      donationCampaign: [],
      uploadFileDetails: {
        file: "",
        dateOfUpload: curr.toISOString().substring(0, 10),
        fileName: "",
        sourceId: 0,
        fileTypeExtension: "",
        batchSource: ''
      },
      batchUploadList: [],
      totalResultes: 0,
      progressBarStatus: 0,
      validationRule: {
        dateOfUpload: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "date of upload"
            ),
          },
        ],
        sourceId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "source"
            ),
          },
        ],
      },
      validationRuleBatchSource: {
        batchSource: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "batch source"),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      batchRecordDetails: {
        batchRecordBasicDetailsId: 0,
        dateOfUpload: "",
        numberOfRecords: 0,
        totalAmount: 0,
        sourceName: "",
        campaignList: [],
      },
      isLoadingForCampaign: false,
      duplicateRecordList: {
        duplicateEmailList: [],
        duplicateNRICList: [],
        duplicateTransactionList: [],
        batchRecordBasicDetailsId: 0,
        dateOfUpload: "",
        numberOfRecords: 0,
        totalAmount: 0,
        sourceName: "",
      },
      batchUploadDetailsAfterUpdate: [],
      setBatchUploadDetailsModal: false,
      setDuplicateEmailDetailModel: false,
      setDuplicateTransactionIdDetailModel: false,
      batchRecordDonorDetailsCounts: 0,
      activeBatchRecordBasicDetailsId: 0,
      activeBatchRecordBasicDetailsIdForDuplicateRecords: 0,
      modalPagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        batchRecordBasicDetailsId: 0
      },
      date: curr.toISOString().substring(0, 10),
    }
  }

  onView = (value, index, event) => {
    this.getBatchRecordDetails(value, event);
  }
  onSubmit = (value, index, event) => {
    this.getDuplicateRecordListByBatchRecordBasicDetailsId(value, event, null);
  }


  actions = (element, index, value) => {
    return element !== "batchRecordBasicDetailsId" ? null : (
      <ActionContainer>
        {/* <ActionEditButton
          value={value}
          id={index}
          index={index}
          // icon={EditIcon}
          onActionClick={this.onView.bind(this)}
          text="view"
        /> */}
        <h2
          className='underline text-[#757575] cursor-pointer'
          onClick={(e) => this.openBatchRecordDonorDetailsList(value)}

        >view</h2>

      </ActionContainer>
    );
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    if (this.onPageChange) {
      this.onPageChange();
    }
    this.getBatchUploadList(newPagination);
  };

  handleChangeInDropdown = (selected, drpIdentity, index) => {
    if (drpIdentity === "Source") {
      var details = this.state.uploadFileDetails;
      details.sourceId = selected.value;
      this.validateField("sourceId");
      this.setState({ progressBarStatus: 0 });
      this.setState({ uploadFileDetails: details });
    }
    if (drpIdentity === "TargetCampaign") {
      let data = this.state.batchRecordDetails;
      data.campaignList[index].targetCampaignName = selected.label;

      this.setState({ batchRecordDetails: data });
    }
  };

  downloadGiveAsiaTemplete = () => {
    this.batchUploadServices.downloadGiveAsiaTemplete().then(
      (response) => {
        if (response != null) {
          var fileName = `Give Asia Template`;
          var blob = new Blob([response.data])
          saveAs(blob, fileName + ".xlsx");
        }
      }
    )
  }

  downloadGivingSGTemplete = () => {
    this.batchUploadServices.downloadGivingSGTemplete().then(
      (response) => {
        if (response != null) {
          var fileName = `Giving SG Template`;
          var blob = new Blob([response.data])
          saveAs(blob, fileName + ".xlsx");
        }
      }
    )
  }
  downloadMasterTemplete = () => {
    this.batchUploadServices.downloadMasterTemplete().then(
      (response) => {
        if (response != null) {
          var fileName = `Master Template`;
          var blob = new Blob([response.data])
          saveAs(blob, fileName + ".xlsx");
        }
      }
    )
  }
  handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    let details = this.state.uploadFileDetails;
    details[name] = value;
    this.setState({ progressBarStatus: 0 });
    this.setState({ uploadFileDetails: { ...details } })
  }

  resetFields = () => {
    let reset = this.state.uploadFileDetails;
    reset.file = "";
    reset.dateOfUpload = this.state.date;
    reset.fileName = "";
    reset.sourceId = 0;
    reset.batchSource = '';
    this.setState({ uploadFileDetails: { ...reset } })
    document.getElementById("file").value = '';
  }

  setFile = (e) => {

    const file = e.target.files[0];
    if (file && (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "text/csv" || file.type === "application/vnd.ms-excel")) {
      let details = this.state.uploadFileDetails;
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        details.fileTypeExtension = ".xlsx";
      } else if (file.type === "application/vnd.ms-excel") {
        details.fileTypeExtension = ".xls";
      } else if (file.type === "text/csv") {
        details.fileTypeExtension = ".csv";
      }
      details["file"] = e.target.files[0];
      details.fileName = file.name;
      this.setState({ uploadFileDetails: { ...details } });
      var curr = new Date();
      details.dateOfUpload = this.state.date + " " + curr.getHours() + ":" + curr.getMinutes() + ":" + curr.getSeconds() + "." + curr.getMilliseconds();
      this.swalServices.Confirm(
        "File",
        "Confirm to upload " + details.fileName + "?",
        SuccessMessage.Yes_Text,
        SuccessMessage.No_Text
      ).then((res) => {
        if (res) {
          var progressStatus = 85;
          this.setState({ progressBarStatus: progressStatus });
          this.batchUploadServices.addFile(details).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
              this.resetFields();
              this.getBatchUploadList(true, response.responseItem.responseContent.keyId, e);
              progressStatus = 100;
              this.setState({ progressBarStatus: progressStatus });
            }
            else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "Invalid Column") {
              this.swalServices.Error("Invalid File, " + response.responseItem.responseContent.errorMessage);
              this.resetFields();
              this.getBatchUploadList();
              progressStatus = 0;
              this.setState({ progressBarStatus: progressStatus });
            }
            else {
              this.swalServices.Error("File already exists.");
              this.resetFields();
              this.getBatchUploadList();
              progressStatus = 0;
              this.setState({ progressBarStatus: progressStatus });
            }
          });
        }
        else {
          this.resetFields();
        }
      })

    }
    else {
      this.swalServices.Error("Invalid file type");
    }

  }

  UploadFile = (e) => {
    if (this.state.uploadFileDetails.sourceId === 3) {
      let isValid = this.isValidateBatchSourceField();
      if (isValid) {
        this.setFile(e);
      } else {
        document.getElementById("file").value = '';
      }
    }
    else {
      let isValid = this.isValidateAllFields();
      if (isValid) {
        this.setFile(e);
      }
      else {
        this.resetFields();
      }
    }
  }

  getBatchUploadList = (isNew = false, keyId = 0, event = null) => {
    this.batchUploadServices
      .getBatchUploadList(this.state.pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let data = response.responseItem.responseContent.itemList;
          data.map((x) => {
            x.dateOfUpload = moment(x.dateOfUpload).format("DD MMM YYYY");
            x.totalAmount = "$" + parseFloat(x.totalAmount).toFixed(2);
            x.fileName = x.fileName ? x.fileName.replace('.xlsx', '') : "-";
          });
          this.setState({ batchUploadList: data, activeBatchRecordBasicDetailsId: keyId }, () => {
            if (isNew === true) {
              this.onView(keyId, 0, event);
            }

          });
          this.setState({ totalResultes: response.responseItem.responseContent.totalCount, });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };


  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.uploadFileDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.uploadFileDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  isValidateBatchSourceField = () => {
    const newValidState = isValidForm(
      this.state.uploadFileDetails,
      this.state.validationRuleBatchSource,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateBatchSource = (key) => {
    const newValidState = validate(
      key,
      this.state.uploadFileDetails,
      this.state.validationRuleBatchSource,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  componentDidMount() {
    this.getBatchUploadList();
  }

  getBatchRecordDetails = (id, event) => {
    this.batchUploadServices.getBatchRecordDetails(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let data = this.state.batchRecordDetails;
        data.campaignList = response.responseItem.responseContent.length > 0 ? response.responseItem.responseContent : [];
        let found = this.state.batchUploadList.find(x => x.batchRecordBasicDetailsId === id);
        if (found) {
          data.batchRecordBasicDetailsId = found.batchRecordBasicDetailsId;
          data.dateOfUpload = found.dateOfUpload;
          data.sourceName = found.sourceName;
          data.numberOfRecords = found.numberOfRecords;
          data.totalAmount = found.totalAmount;

          this.getDonationName();
          this.setState({ batchRecordDetails: data, }, () => {
            event.stopPropagation();
            this.setState({ setAddEditBatchUpload: true });
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  getDonationName = () => {
    this.setState({ isLoadingForCampaign: true });
    this.batchUploadServices.getDonationPurposeName().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ donationCampaign: response.responseItem.responseContent, }, () => this.setState({ isLoadingForCampaign: false }));
      } else {
        this.swalServices.Error("Something wents wrong!");
        this.setState({ isLoadingForCampaign: false });
      }
    });
  };

  updateTargetCampaign = (event) => {
    if (this.state.batchRecordDetails.campaignList.length > 0) {
      let request = {};

      let tempCampaignList = this.state.batchRecordDetails.campaignList;

      tempCampaignList.map((campaign) => {
        campaign.recipientCampaign = campaign.recipientCampaign;
        campaign.numberOfRecords = campaign.numberOfRecords;
        campaign.batchRecordBasicDetailsId = campaign.batchRecordBasicDetailsId;

        if (campaign.targetCampaignName == null) {
          campaign.targetCampaignName = campaign.recipientCampaign;
        } else {
          campaign.targetCampaignName = campaign.targetCampaignName;
        }
      })

      request.campaignList = tempCampaignList;

      this.batchUploadServices.updateTargetCampaign(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.keyId > 0) {
            this.swalServices.Success("Target campaign updated successfully.")
            this.setState({ activeBatchRecordBasicDetailsIdForDuplicateRecords: response.responseItem.responseContent.keyId },
              () => { this.getDuplicateRecordListByBatchRecordBasicDetailsId(response.responseItem.responseContent.keyId, 1, null); })
          }

        }
      });
    }
  };

  getDuplicateRecordListByBatchRecordBasicDetailsId = (id, flag, CheckForUpdateDone) => {

    this.batchUploadServices.getDuplicateRecordListByBatchRecordBasicDetailsId(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.duplicateEmailList.length > 0 || response.responseItem.responseContent.duplicateNRICList.length > 0 || response.responseItem.responseContent.duplicateTransactionList.length > 0) {
          let data = this.state.duplicateRecordList;
          data.duplicateEmailList = response.responseItem.responseContent.duplicateEmailList.length > 0 ? response.responseItem.responseContent.duplicateEmailList : [];
          data.duplicateNRICList = response.responseItem.responseContent.duplicateNRICList != null && response.responseItem.responseContent.duplicateNRICList.length > 0 ? response.responseItem.responseContent.duplicateNRICList : [];
          data.duplicateTransactionList = response.responseItem.responseContent.duplicateTransactionList != null && response.responseItem.responseContent.duplicateTransactionList.length > 0 ? response.responseItem.responseContent.duplicateTransactionList : [];

          let found = this.state.batchUploadList.find(x => x.batchRecordBasicDetailsId === id);
          if (found) {
            data.batchRecordBasicDetailsId = found.batchRecordBasicDetailsId;
            data.dateOfUpload = found.dateOfUpload;
            data.sourceName = found.sourceName;
            data.numberOfRecords = found.numberOfRecords;
            data.totalAmount = found.totalAmount;

            this.setState({ duplicateRecordList: data, }, () => {
              this.setState({ setAddEditBatchUpload: false });
              // if (flag) {
              //   this.setState({ setDuplicateRecordDetails: true });
              // }
              // if (CheckForUpdateDone == "EmailUpdateDone") {
              //   data.duplicateEmailList = [];
              // }
              // if (CheckForUpdateDone == "NRICUpdateDone") {
              //   data.duplicateNRICList = [];
              // }
              // if (CheckForUpdateDone == "TransationIdUpdateDone") {
              //   data.duplicateTransactionList = [];
              // }


              // if (data.duplicateNRICList.length > 0) {
              //   this.setState({ setDuplicateRecordDetails: true });
              // }
              // else if (data.duplicateEmailList.length > 0) {
              //   this.setState({ setDuplicateEmailDetailModel: true });
              // }
              // else if (data.duplicateTransactionList.length > 0) {
              //   this.setState({ setDuplicateTransactionIdDetailModel: true });
              // }
              if (data.duplicateTransactionList.length > 0) {
                this.setState({ setDuplicateTransactionIdDetailModel: true });
              }
              else if (data.duplicateNRICList.length > 0) {
                this.setState({ setDuplicateRecordDetails: true });
              }
              else if (data.duplicateEmailList.length > 0) {
                this.setState({ setDuplicateEmailDetailModel: true });
              }


              // this.setState({ setBatchUploadDetailsModal: false,setDuplicateEmailDetailModel: false,setDuplicateTransactionIdDetailModel: false,});
              data.duplicateEmailList.forEach(element => {
                if (element.isSelected === true) {
                  this.handleChangeRadioButton(element, 'DuplicateEmail');
                }
              });
              data.duplicateNRICList.forEach(element => {
                if (element.isSelected === true) {
                  this.handleChangeRadioButton(element, 'DuplicateNRIC');
                }
              });
              data.duplicateTransactionList.forEach(element => {
                if (element.isSelected === true) {
                  this.handleChangeRadioButton(element, 'DuplicateTransaction');
                }
              });
            });
          }
        } else {
          let reqPrm = [id, this.state.batchRecordDetails.dateOfUpload];
          this.batchUploadServices.getBatchRecordDonorDetails(reqPrm).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
            }
            else {
              this.swalServices.Error(response.message);
            }
          })
          this.setState({ setAddEditBatchUpload: false });
          this.openBatchRecordDonorDetailsList(id);
        }
      }
      else {
        this.swalServices.Error("Something wents wrong!");
      }
    });
  };


  handleChangeRadioButton(data, identity, event) {
    let duplicateDetails = this.state.duplicateRecordList;
    if (identity === "DuplicateEmail") {
      let emailList = duplicateDetails.duplicateEmailList;
      let counter = 0;
      emailList.map((details) => {
        if (details.donorEmail != null && data.donorEmail !== "" && details.donorEmail === data.donorEmail) {

          if (details.donorEmail === data.donorEmail && details.batchRecordDonorDetailsId === data.batchRecordDonorDetailsId) {
            details.isSelected = true;
            details.result = data.donorEmail;
          } else {
            details.isSelected = false;
            counter = counter + 1
            let splitEmail = data.donorEmail.split("@");
            let finalEmail = splitEmail[0] + '+' + counter + '@' + splitEmail[1];
            details.result = finalEmail;
          }
        }
      })
      duplicateDetails.duplicateEmailList = emailList;
      this.setState({ duplicateRecordDetails: duplicateDetails });
    }

    if (identity === "DuplicateTransaction") {
      let transactionList = duplicateDetails.duplicateTransactionList;
      let alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      let counter = 0;
      transactionList.map((details) => {
        if (details.transactionId != null && data.transactionId !== "" && details.transactionId === data.transactionId) {

          if (details.transactionId === data.transactionId && details.batchRecordDonorDetailsId === data.batchRecordDonorDetailsId) {
            details.isSelected = true;
            details.result = data.transactionId;
          } else {
            details.isSelected = false;
            details.result = details.transactionId + alphabets[counter];
            counter = counter + 1
          }
        }
      })
      duplicateDetails.duplicateTransactionList = transactionList;
      this.setState({ duplicateRecordDetails: duplicateDetails });
    }

    if (identity === "DuplicateNRIC") {
      let nricList = duplicateDetails.duplicateNRICList;
      nricList.map((details) => {
        if (details.nric != null && data.nric !== "" && details.nric === data.nric) {

          if (details.nric === data.nric && details.batchRecordDonorDetailsId === data.batchRecordDonorDetailsId) {
            details.isSelected = true;
            details.result = data.donorEmail;
          } else {
            details.isSelected = false;
            details.result = data.donorEmail;
          }
        }
      })
      duplicateDetails.duplicateNRICList = nricList;
      this.setState({ duplicateRecordDetails: duplicateDetails });
    }
  }

  updateDuplicateRecords = (event) => {
    this.batchUploadServices.updateDuplicateRecords(this.state.duplicateRecordList).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.keyId > 0) {
          this.swalServices.Success("Duplicate NRIC records updated successfully.")
          this.getDuplicateRecordListByBatchRecordBasicDetailsId(this.state.activeBatchRecordBasicDetailsIdForDuplicateRecords, null, "NRICUpdateDone");
          // this.batchUploadServices.getBatchRecordDonorDetails(this.state.duplicateRecordList.batchRecordBasicDetailsId).then((response) => {
          //   if (response.statusCode === 200 && response.responseItem != null) {
          //   }
          //   else {
          //     this.swalServices.Error(response.message);
          //   }
          // })
          // this.openBatchRecordDonorDetailsList(this.state.duplicateRecordList.batchRecordBasicDetailsId);
        }
        // this.setState({ setDuplicateRecordDetails: false, setDuplicateEmailDetailModel: true });
        this.setState({ setDuplicateRecordDetails: false });
      }
      else {
        this.swalServices.Error(response.message);
        this.setState({ setDuplicateRecordDetails: false, setDuplicateEmailDetailModel: true });
      }
    });
  };

  updateDuplicateEmailRecords = (event) => {
    this.batchUploadServices.updateDuplicateEmailRecords(this.state.duplicateRecordList).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.keyId > 0) {
          this.swalServices.Success("Duplicate email records updated successfully.")
          // this.batchUploadServices.getBatchRecordDonorDetails(this.state.duplicateRecordList.batchRecordBasicDetailsId).then((response) => {
          //   if (response.statusCode === 200 && response.responseItem != null) {
          //   }
          //   else 
          //   {
          //     this.swalServices.Error(response.message);
          //   }
          // })
        }
        this.setState({ setDuplicateEmailDetailModel: false });
        this.getDuplicateRecordListByBatchRecordBasicDetailsId(this.state.activeBatchRecordBasicDetailsIdForDuplicateRecords, null, "EmailUpdateDone");
        // this.setState({ setDuplicateTransactionIdDetailModel: true });
      }
    });
  };

  updateDuplicateTransactionIdRecords = (event) => {
    this.batchUploadServices.updateDuplicateTransactionIdRecords(this.state.duplicateRecordList).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.keyId > 0) {
          this.swalServices.Success("Duplicate email records updated successfully.")

          // let reqPrm = [this.state.duplicateRecordList.batchRecordBasicDetailsId, this.state.batchRecordDetails.dateOfUpload];
          // this.batchUploadServices.getBatchRecordDonorDetails(reqPrm).then((response) => {
          //   if (response.statusCode === 200 && response.responseItem != null) {
          //     this.setState({ setDuplicateEmailDetailModel: false });
          //   }
          //   else {
          //     this.swalServices.Error(response.message);
          //     this.setState({ setDuplicateEmailDetailModel: false });
          //   }
          // })
        }
        // else {
        //   let reqPrm = [this.state.duplicateRecordList.batchRecordBasicDetailsId, this.state.batchRecordDetails.dateOfUpload];
        //   this.batchUploadServices.getBatchRecordDonorDetails(reqPrm).then((response) => {
        //     if (response.statusCode === 200 && response.responseItem != null) {
        //       this.setState({ setDuplicateEmailDetailModel: false });
        //     }
        //     else {
        //       this.swalServices.Error(response.message);
        //       this.setState({ setDuplicateEmailDetailModel: false });
        //     }
        //   })
        // }
        this.getDuplicateRecordListByBatchRecordBasicDetailsId(this.state.activeBatchRecordBasicDetailsIdForDuplicateRecords, null, "TransactionIdUpdateDone");
        // this.openBatchRecordDonorDetailsList(this.state.duplicateRecordList.batchRecordBasicDetailsId);
        this.setState({ setDuplicateTransactionIdDetailModel: false });
      }
    });
  };

  getBatchRecordDonorDetailsList = () => {
    let request = { ...this.state.modalPagination };
    request.batchRecordBasicDetailsId = this.state.activeBatchRecordBasicDetailsId;
    if (request.batchRecordBasicDetailsId > 0) {
      this.batchUploadServices.getBatchRecordDonorDetailsList(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let data = response.responseItem.responseContent.itemList;
          data.map((x) => {
            //let date = new Date(x.time);
            let date = moment(x.time, "DD/MM/YYYY");
            x.time = x.time ? moment(date).format("DD MMM YYYY") : "-";
            x.donation = x.donation ? "$" + parseFloat(x.donation).toFixed(2) : "-";
            x.donorName = x.donorName ? x.donorName : "-";
            if(x.sourceId === 2){
                let address = "";
                address = address + (x.block  ? x.block + " " : "");
                address = address + (x.street ? x.street + " " : "");
                address = address + (x.buildingName  ? x.buildingName + " " : "");
                address = address + (x.floor  ? "#" + x.floor : "");
                address = address + (x.unitNumber  ? "-" + x.unitNumber + " " : "");
                address = address + (x.postalCode  ? x.postalCode : "");
                x.address = address;
            }else{
            x.address = x.address ? x.address : "-";
            }
            x.id = x.id ? x.id : "-";
            x.anonymous = x.anonymous ? x.anonymous : "-";
          });
          this.setState({ batchUploadDetailsAfterUpdate: data, });
          this.setState({ batchRecordDonorDetailsCounts: response.responseItem.responseContent.totalCount });
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  setModalPagination = (newPagination) => {
    this.setState({ modalPagination: newPagination });
    if (this.onPageChange) {
      this.onPageChange();
    }
    this.getBatchRecordDonorDetailsList();
  };

  openBatchRecordDonorDetailsList = (id) => {
    this.setState({
      setBatchUploadDetailsModal: true, activeBatchRecordBasicDetailsId: id, setDuplicateTransactionIdDetailModel: false
    },
      () => { this.getBatchRecordDonorDetailsList(); });
  }

  deleteBatchRecordDetails = () => {
    let request = {
      BatchRecordBasicDetailsId: this.state.activeBatchRecordBasicDetailsId
    }
    this.batchUploadServices.deleteBatchRecordDetails(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Batch Records Deleted");
        this.getBatchUploadList();
        this.setState({ progressBarStatus: 0 }, () => { this.getBatchUploadList() });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  render() {
    return (

      <div className="main-body-section ">
        <div className="bg-white ">
          <div class="heading-part py-3 mb-7">
            <div class="flex items-center justify-between">
              <div className="left-tiitle ">
                <h3 class="font-bold  text-[#757575] text-4xl">
                  Upload Record
                </h3>
              </div>
            </div>
          </div>

          <div className="bg-white border border-[#c9c9c9] pb-9 ">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 pl-7 md:pr-7">

              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="col-span-5">
                  <h2 className="font-semibold pb-2 text-[20px] text-[#757575] pt-7">Date of Upload
                    <span className="text-[#C00000]">*</span>
                  </h2>

                </div>
                <div className="col-span-7">
                  <div className='pt-7 flex justify-center flex-wrap text-[#0e61b4] size-link'>
                  <a href="#" onClick={this.downloadGiveAsiaTemplete.bind(this)}>(Download Give Asia Template)
                  </a>
                  <span className='pl-3'>               </span>
                  <a href="#" onClick={this.downloadGivingSGTemplete.bind(this)}>(Download Giving.SG Template)
                  </a>
                  <a href="#" onClick={this.downloadMasterTemplete.bind(this)}>(Download Master Template)
                  </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:col-span-10 lg:col-span-10 col-span-12 pl-7 md:pr-7">
              <div className="grid grid-cols-12 gap-6 w-full items-center">
                <div className="xl:col-span-7 lg:col-span-12 col-span-12">


                  <div className="xl:col-span-7 lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                      <div className="col-span-8">
                        <input
                          id="dateOfUpload"
                          name="dateOfUpload"
                          placeholder="Select"
                          className="date-pickers form-input rounded-none w-full  text-lg"
                          type="date"
                          value={this.state.uploadFileDetails.dateOfUpload}
                          onChange={this.handleChange.bind(this)}
                          onBlur={() => this.validateField("dateOfUpload")}
                        />
                        <ValidationText
                          className="absolute left-0 bottom-0"
                          error={this.state.validState.error.dateOfUpload}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-6 w-full mt-6">
                    <div className="col-span-8">
                      <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">Source of Upload
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <MultiSelectDropdown
                        options={this.state.source}
                        //isMulti
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={this.handleChangeInDropdown.bind(this)}
                        allowSelectAll={false}
                        value={this.state.source.filter(
                          (item) =>
                            item.value === this.state.uploadFileDetails.sourceId
                        )}
                        drpIdentity="Source"
                      />
                      <div>
                        <ValidationText
                          error={this.state.validState.error.sourceId}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.uploadFileDetails.sourceId === 3 ?
                    <div className="grid grid-cols-12 gap-6 w-full mt-6">
                      <div className="col-span-8">
                        <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">Batch Source
                          <span className="text-[#C00000]">*</span>
                        </h2>
                        <TextInput
                          className="w-full outline-none appearance-none"
                          placeholder="batchSource"
                          type="text"
                          name="batchSource"
                          value={this.state.uploadFileDetails.batchSource}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateBatchSource}
                          // validState={this.state.validState}
                          error={this.state.validState.error.batchSource}
                        />
                        <div>
                        </div>
                      </div>
                    </div>
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6 w-full mt-6">
                <div className="xl:col-span-7  lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="col-span-8">
                      <h2 className="font-semibold pb-2 text-[20px] text-[#757575] ">Batch Upload
                        <span className="text-[#C00000]">*</span>
                      </h2>

                      {/* <span className="text-xl  font-medium theme-color mr-5">
                            PDF Upload
                          </span> */}

                      <form className="custom-uploader border flex justify-between border-[#757575] text-center pr-7 pl-5 py-4">
                        <span>{this.state.uploadFileDetails.fileName}</span>

                        <img
                          className="uplod-svg inline-flex w-5 h-5 fill-[#757575] ml-4"
                          src="/pdf-upload.svg"
                        ></img>

                      </form>
                    </div>

                    <div className="col-span-4 w-full flex justify-end items-center pt-9">
                      <div class="upload-btn-wrapper">
                        <button class="btn">
                          <CustomButton
                            text="Upload"
                            width="100px"
                          />
                        </button>
                        <input
                          id='file'
                          type="file"
                          name="myfile"
                          onChange={(e) => this.UploadFile(e)}
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='md:pr-14'>
              <div className="grid grid-cols-12 gap-3 w-full ">
                <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                  <div className="pl-14 pt-7 ">
                    <div className="overflow-hidden h-5 mb-4 text-xs flex rounded-[30px] progresss-sec ">
                      <div className="progress shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center" style={{ width: `${this.state.progressBarStatus}%` }}></div>
                    </div>

                  </div>
                </div>
                <div className='per pl-14 pt-6 text-[#757575] text-xl '>
                  {`${Math.round(this.state.progressBarStatus)}%`}
                </div>
              </div>
            </div>



          </div>
          <div className="pt-12">
            <Table
              columns={[
                { name: "dateOfUpload", title: "Date of Upload" },
                { name: "sourceName", title: "Source of Upload" },
                { name: "numberOfRecords", title: "Number of Records" },
                { name: "totalAmount", title: "Total Amount" },
                { name: "fileName", title: "File Name" },
                // { name: "uploadBy", title: "Uploaded By" },
                { name: "batchRecordBasicDetailsId", title: " Actions" },
              ]}
              rows={this.state.batchUploadList}
              isLoading={this.state.isLoading}
              sortingColumns={[""]}
              pagination={this.state.pagination}
              totalResultes={this.state.totalResultes}
              totalCount={this.state.totalResultes}
              setPagination={this.setPagination.bind(this)}
              customScope={[
                {
                  column: "batchRecordBasicDetailsId",
                  renderTableData: this.actions.bind(this),
                },
              ]}
            />
          </div>
        </div>
        <RecordDetailModel
          setAddEditBatchUpload={this.state.setAddEditBatchUpload}
          setOpenModal={() => this.setState({ setAddEditBatchUpload: false }, () => { this.deleteBatchRecordDetails(); })}
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ setAddEditBatchUpload: false });
            let resetField = {
              batchRecordBasicDetailsId: 0,
              dateOfUpload: "",
              numberOfRecords: 0,
              totalAmount: 0,
              sourceName: "",
              campaignList: [],
            }
            this.setState({ batchRecordDetails: resetField });
          }}
          donationCampaign={this.state.donationCampaign}
          handleChangeInDropdown={this.handleChangeInDropdown.bind(this)}
          duplicateRecordDetails={this.state.duplicateRecordDetails}
          batchRecordDetails={this.state.batchRecordDetails}
          updateTargetCampaign={this.updateTargetCampaign.bind(this)}
          isLoadingForCampaign={this.state.isLoadingForCampaign}
        />

        <DuplicateRecordDetailModel
          setDuplicateRecordDetails={this.state.setDuplicateRecordDetails}
          setOpenModal={() => this.setState({ setDuplicateRecordDetails: false }, () => { this.deleteBatchRecordDetails(); })}
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ setDuplicateRecordDetails: false });
          }}
          duplicateRecordList={this.state.duplicateRecordList}
          handleChangeRadioButton={this.handleChangeRadioButton.bind(this)}
          updateDuplicateRecords={this.updateDuplicateRecords.bind(this)}
        />
        <DuplicateEmailDetailModel
          setDuplicateEmailDetailModel={this.state.setDuplicateEmailDetailModel}
          setOpenModal={() => this.setState({ setDuplicateEmailDetailModel: false }, () => { this.deleteBatchRecordDetails(); })}
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ setDuplicateEmailDetailModel: false });
          }}
          duplicateRecordList={this.state.duplicateRecordList}
          handleChangeRadioButton={this.handleChangeRadioButton.bind(this)}
          updateDuplicateEmailRecords={this.updateDuplicateEmailRecords.bind(this)}
        />
        <DuplicateTransactionIdDetailModel
          setDuplicateTransactionIdDetailModel={this.state.setDuplicateTransactionIdDetailModel}
          setOpenModal={() => this.setState({ setDuplicateTransactionIdDetailModel: false }, () => { this.deleteBatchRecordDetails(); })}
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ setDuplicateTransactionIdDetailModel: false });
          }}
          duplicateRecordList={this.state.duplicateRecordList}
          handleChangeRadioButton={this.handleChangeRadioButton.bind(this)}
          updateDuplicateTransactionIdRecords={this.updateDuplicateTransactionIdRecords.bind(this)}
        />

        <BatchUploadDetailsModal
          setBatchUploadDetailsModal={this.state.setBatchUploadDetailsModal}
          setOpenModal={() => this.setState({ setBatchUploadDetailsModal: false })}
          onClose={(e) => {
            e.stopPropagation();
            // this.setState({ setBatchUploadDetailsModal: false });
            // let resetField = {
            //   batchRecordBasicDetailsId: 0,
            //   dateOfUpload: "",
            //   numberOfRecords: 0,
            //   totalAmount: 0,
            //   sourceName: "",
            //   campaignList: [],
            // }
            // this.setState({ batchRecordDetails: resetField });
          }}
          batchUploadDetailsAfterUpdate={this.state.batchUploadDetailsAfterUpdate}
          rows={this.state.batchUploadDetailsAfterUpdate}
          totalResultes={this.state.batchRecordDonorDetailsCounts}
          pagination={this.state.modalPagination}
          setModalPagination={(pagination) => this.setModalPagination(pagination)}
          isClose={true}
        />
        {/* {this.state.setBatchUploadDetailsModal === true ?
          <ModalBasic
            id="add-edit-strategic-member-modal"
            modalOpen={this.state.setBatchUploadDetailsModal}
            setModalOpen={() => this.setState({ setBatchUploadDetailsModal: false })}
            title="Batch Upload Details"
            noCloseIcon={false}
          >
              <div className="pt-12">
                <Table
                  columns={[
                    { name: "dateOfUpload", title: "Date of Upload" },
                    { name: "doantion", title: "doantion" },
                  ]}
                  rows={this.state.batchUploadDetailsAfterUpdate}
                  isLoading={this.state.isLoading}
                  sortingColumns={[""]}
                  pagination={this.state.modalPagination}
                  totalResultes={this.state.batchRecordDonorDetailsCounts}
                  totalCount={this.state.batchRecordDonorDetailsCounts}
                  setPagination={() => this.setModalPagination()}
                //   customScope={[
                //     {
                //       column: "batchRecordBasicDetailsId",
                //       renderTableData: this.actions.bind(this),
                //     },
                //   ]}
                />
              </div>
        
        </ModalBasic>
          : null} */}
      </div >


      //
    )
  }
}
